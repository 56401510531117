body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*:focus {
  box-shadow: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
form {
  color: gray;
}
#sidebar .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
  background-color: transparent;
  color: #000;
  border-radius: 3px;
}

/*#sidebar .pro-sidebar {*/
/*  width: 230px;*/
/*  min-width: 230px;*/
/*}*/

/*#sidebar .pro-sidebar.collapsed {*/
/*  width: 80px;*/
/*  min-width: 80px;*/
/*}*/

#sidebar .pro-sidebar-inner {
  background-color: transparent;
}

#sidebar .pro-menu a {
  color: #ccd2e3;
  font-size: 18px;
}

#sidebar .pro-sidebar-inner .pro-sidebar-layout {
  overflow: visible;
}

#sidebar .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-header {
  border: none;
}

.hide {
  visibility: hidden;
}

.remove {
  display: none;
}

.logo {
  width: 45px;
  height: 45px;
}

.highlight {
  background: #1b2b65;
}

.no-cursor > .pro-inner-item {
  cursor: default !important;
}

.menu-expand-button {
  color: #445bff;
  padding: 5px;
  background: white;
  position: absolute;
  right: -10px;
  z-index: 9999;
  line-height: 20px;
  border-radius: 50%;
  border: 1px solid #dadada;
  font-weight: bold;
  font-size: 22px;
  cursor: pointer;
  top: 28px;
  display: none;
}
.banner {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 24px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
}
.trynow {
      background: white;
    padding: 8px 33px;
    border-radius: 8px;
    color: black;
    font-size: 15px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -.01em;
    text-align: left;
    margin-left: 40px;
    margin-right: 4px;
  
}
.trynow:hover{
  box-shadow: 2px 8px 10px #010e18;
}
.newBadge {
  display: none;
  background: #0f62ff;
  mix-blend-mode: normal;
  border: 0.734783px solid rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  height: 24px;
  width: 46px;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  margin-right: 4px;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #ffffff !important;
}
.banner-text {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bannermiddle {
  width: 100%;
  text-align: center;
}
.bannerright {
  text-align: right;
  margin-right: 27.5px;
}

@media (min-width: 992px) {
  .md-menu {
    display: none !important;
  }
  .menu-expand-button {
    display: block;
  }
}

::-webkit-scrollbar {
  left: -10px;
  width: 8px;
  border-radius: 12px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 12px;
  box-shadow: inset 0 0 10px 10px transparent;
  border: solid 4px transparent;
}

::-webkit-scrollbar-track {
  border-radius: 12px;
  box-shadow: inset 0 0 10px 10px transparent;
  border: solid 4px transparent;
  background-color: transparent;
}
